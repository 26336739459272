

























































































import { Component, Vue } from 'vue-property-decorator'
import LsEditor from '@/components/editor.vue'
import lsDialog from '@/components/ls-dialog.vue'
import MaterialSelect from '@/components/material-select/index.vue'
import {
    apiRechargeGetRule,
    apiRechargeSetRule
} from '@/api/application/recharge'

@Component({
    components: {
        MaterialSelect,
        lsDialog,
        LsEditor
    }
})
export default class RechargeRuleEdit extends Vue {
    /** S Data **/

    list: any = {
        set: {
            open: 1,
            min_amount: 100
        },
        rule: [
            {
                money: 50,
                award: [
                    {
                        give_money: 5
                    }
                ]
            }
        ]
    }

    /** E Data **/

    /** S Methods **/

    addRule() {
        if (this.list.rule.length >= 10) {
            return this.$message.error('不能继续添加了!')
        }
        this.list.rule.push({
            money: 0,
            award: [
                {
                    give_money: 0
                }
            ]
        })
    }

    // 删除规则规格项
    delRule(index: number) {
        if (this.list.rule.length <= 1) {
            return this.$message.error('已经是最后一个了!')
        }
        this.list.rule.splice(index, 1)
    }

    onSubmit() {
        const list = {
            rule: this.list.rule,
            ...this.list.set
        }
        try {
            list.rule.forEach((item: any) => delete item.id)
        } catch (error) {}
        apiRechargeSetRule({ ...list })
            .then(() => {
                this.detail()
                this.$message.success('修改成功!')
            })
            .catch(() => {
                this.$message.error('数据获取失败!')
            })
    }

    // 详情
    detail() {
        apiRechargeGetRule({})
            .then(res => {
                this.list = res
            })
            .catch(() => {
                this.$message.error('数据获取失败!')
            })
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.detail()
    }
    /** E Life Cycle **/
}
